

import { Component, Vue, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "@/config";
import PreRequisiteHeader from "@/views/preRequisiteHeader/PreRequisiteHeader.vue";

import { mixins } from "vue-class-component";
import CommonMixin from "@/mixins/common.mixin";
import LenderMixin from "@/mixins/lender.mixin";

@Component({
  components: {
    PreRequisiteHeader
  }
})
export default class LenderInfo extends mixins(CommonMixin, LenderMixin) {
  
  mounted() {
    this.getAppovedLenderList();
    if (this.$loader) {
      this.$loader.hide();
    }
  }

  beforeDestroy() {
    if (this.$route.name == "Information") {
      this.$snotify.clear();
      this.$snotify.info("Sign-Up is in process. You cannot go back.", {
        timeout: 999999
      });
      this.$router.push({ name: "LenderInfo" });
    }
  }
 
}
