


import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../../../config";

import { HollowDotsSpinner } from "epic-spinners";
import LenderInformation from "@/components/broker/onBoarding/lender-information/LenderInformation.vue";
import { mixins } from "vue-class-component";
import CommonMixin from "@/mixins/common.mixin";
import MailLender from "@/views/sendMailLender.vue";

@Component({ components: { HollowDotsSpinner, LenderInformation, MailLender } })
export default class LenderRelationsComponent extends mixins(CommonMixin) {
  public defaultLabelName = "Add Lender";
  public defaultButtonName = "Add";
  public editLenderData: any = {};
  public lenders = [];
  public lendersShowingPassword = [];
  public inProcess = false;
  public lenderId = null;
  public delete = "yes";
  public dotLoader = false;
  public searchName = "";
  //public allLenders = [];
  public lenderLoader = false;
  public loadMoreScroll = false;
  public lenderLen = 0;
  public searchByType = null;
  public searchString = "";
  public isCustomLenderApproved = false;
  public lenderList: any = [];
  public addedLenders: any = [];
  public errorMessage = null;
  public hasErrorMessage = false;
  public isPpeLender = false;
  public ppelenderName = null;
  public hasLenderName = true;
  public lenderInfo: any = {
    lenderNameAndId: {}
  };
  public openSendModel: any = false;
  public selectLender: boolean = false;
  public phoneError = false;
  public phoneNumber: any = {
    first: null,
    middle: null,
    last: null
  };
  get userType() {
    return this.$store.state.sessionObject.type;
  }
  public togglePasswordDisplay(lender) {
    this.lendersShowingPassword = this.lendersShowingPassword.includes(
      lender._id
    )
      ? this.lendersShowingPassword.filter(id => id != lender._id)
      : [lender._id, ...this.lendersShowingPassword];
  }
  //   public async getBrokerLenderList(mountCall: Boolean = false) {
  //     try {
  //       this.dotLoader = true;
  //       let body = {
  //         brokerId: this.returnBrokerId(),
  //         userType: this.userType,
  //         userId: this.$userId,
  //         identifier: "BothLender",
  //         fieldName: this.searchByType,
  //         searchString:
  //             this.searchString != "" && this.searchString != null
  //               ? this.searchString
  //               : null,
  //       };

  //       let response = await Axios.post(
  //         BASE_API_URL + "broker/getBrokerLenderList",
  //         body,
  //         {
  //
  //         }
  //       );
  //       if (this.userType == "MLO" || this.userType == "Admin Staff") {
  //         response.data.lenderData.forEach(element => {
  //           element["allowEdit"] = true;
  //           element["allowDelete"] = true;
  //           if (
  //             element.addedByUserType == "Broker" ||
  //             element.addedByUserType == "Super Admin"
  //           ) {
  //             element.brokerlenderReferenceId = element._id;
  //             delete element._id;
  //             element.userName = null;
  //             element.password = null;
  //             element.allowDelete = false;
  //           }
  //         });
  //       }
  //       if (this.userType == "Admin Staff") {
  //         response.data.lenderData.forEach(element => {
  //           if (element.addedByUserType == "MLO") {
  //             element.userName = null;
  //             element.password = null;
  //             element.allowEdit = false;
  //             element.allowDelete = false;
  //           }
  //         });
  //       }
  //       if (this.userType == "Broker") {
  //         response.data.lenderData.forEach(element => {
  //           element["allowEdit"] = true;
  //           element["allowDelete"] = true;
  //           if (
  //             element.addedByUserType == "MLO" ||
  //             element.addedByUserType == "Admin Staff"
  //           ) {
  //             element.userName = null;
  //             element.password = null;
  //             element.allowEdit = false;
  //             element.allowDelete = false;
  //           }
  //         });
  //       }

  //         this.lenders = response.data.lenderData;
  //         //this.allLenders = response.data.lenderData;
  //         if(this.searchString==null||this.searchString==""){
  //       this.lenderLen =this.lenders.length;
  //       }
  //       this.dotLoader = false;
  //     } catch (error) {
  //       this.dotLoader = false;
  //       console.log(error);
  //     }
  //   }

  //   public returnBrokerId() {
  //     if (this.$store.state.sessionObject.type == "Broker") {
  //       return this.$brokerId;
  //     } else {
  //       return this.$store.state.sessionObject.userInfo.brokerId;
  //     }
  //   }

  //   public allowEditLenders(lender) {
  //     if (this.userType == "Broker") {
  //       if (
  //         lender.addedBy == this.$userId ||
  //         lender.addedByUserType == "Super Admin"
  //       ) {
  //         return true;
  //       } else {
  //         return false;
  //       }
  //     } else {
  //       return true;
  //     }
  //   }
  public getValueOnChange() {
    if (this.searchByType == null) {
      this.lenders = [];
      this.searchByType = null;
      this.searchString = null;
      this.getAppovedLenderList();
    }
  }
  public async getTotalLenderCount() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "broker/getTotalLenderCount/",
        {
          userId: this.$userId
        });
      this.lenderLen = response.data.lenderLen;
    } catch (error) {
      console.log(error);
    }
  }

  //To get the Approved lender data
  public async getAppovedLenderList() {
    try {
      // this.lenderLoader = true;
      let filteredLender = null;
      let isLenderAdded = false;
      let queryParamLender = this.$route.query.selectedLender;
      let body = {
        userId: this.$userId,
        brokerId: this.$brokerId,
        userType: this.$userType,
        fieldName: this.searchByType,
        type: this.defaultLabelName,

        searchString:
          this.searchString != "" && this.searchString != null
            ? this.searchString
            : null
      };
      let response = await Axios.post(
        BASE_API_URL + "onBoarding/getAppovedLenderList",
        body);
      // this.lenderLoader = false;
      this.lenderList = response.data.lender;
      this.addedLenders = response.data.addedData;
      this.lenderList.push({ lenderName: "Others", _id: "others" });
      isLenderAdded = this.addedLenders.find(lender => {
        return lender.lenderName == queryParamLender;
      });
      if (queryParamLender && !isLenderAdded) {
        filteredLender = this.lenderList.find(lender => {
          return lender.lenderName == queryParamLender;
        });
        if (filteredLender) this.getData(filteredLender);
        this.$modal.show("addLender");
      }
    } catch (error) {
      console.log(error.response);
    }
  }

  public async saveLenderInfo() {
    try {
      this.inProcess = true;
      let formValid = await this.$validator.validateAll();
      let isRequired = this.checkValidation(formValid);
      if (isRequired) return;
      let lenderName = null;
      let lenderId = null;
      if (
        this.lenderInfo.lenderNameAndId == "Others" ||
        this.lenderInfo.lenderNameAndId.lenderName == "Others"
      ) {
        lenderName = this.lenderInfo.lenderName;
        lenderId = this.lenderInfo.lenderNameAndId._id
          ? this.lenderInfo.lenderNameAndId._id
          : null;
      } else {
        lenderName = this.lenderInfo.lenderNameAndId.lenderName;
        lenderId = this.lenderInfo.lenderNameAndId._id;
      }
      this.phoneError = false;
      this.lenderInfo.phone = `(${this.phoneNumber.first}) ${
        this.phoneNumber.middle
      }-${this.phoneNumber.last}`;
      let lenderData: any = {
        lenderName: lenderName,
        lenderId: lenderId,
        brokerId: this.$brokerId,
        userType: this.$userType,
        lenderInfo: this.lenderInfo,
        type: this.defaultButtonName,
        userId: this.$userId
      };

      let response = await Axios.post(
        BASE_API_URL + "onBoarding/saveLenderInfo",
        { lenderData });
      if (response.data.statusCode == 500) {
        this.inProcess = false;
        this.hasErrorMessage = true;
        this.errorMessage = response.data.message;
      } else {
        this.getAppovedLenderList();
        this.$snotify.success("Lender added successfully!");
        this.inProcess = false;
        this.hideModel();
      }
    } catch (error) {
      console.log(error);
    }
  }
  public moveToSecond() {
    this.phoneError = false;
    if (this.phoneNumber.first.length == 3) {
      document.getElementById("second").focus();
    }
  }

  public moveToThird() {
    this.phoneError = false;
    if (this.phoneNumber.middle.length == 3) {
      document.getElementById("third").focus();
    }
  }
  public checkValidation(formValid) {
    let isRequired = false;
    if (!formValid) {
      this.inProcess = false;
      isRequired = true;
    }
    if (
      this.phoneNumber.first == null ||
      this.phoneNumber.first == "" ||
      this.phoneNumber.middle == null ||
      this.phoneNumber.middle == "" ||
      this.phoneNumber.last == null ||
      this.phoneNumber.last == ""
    ) {
      this.phoneError = true;
      this.inProcess = false;
      isRequired = true;
    }
    return isRequired;
  }
  public changeLenderName(type) {
    this.hasErrorMessage = false;
    if (type == "input") {
      if (this.isPpeLender) this.ppelenderName = this.lenderInfo.lenderName;
    } else {
      this.hasLenderName = true;
      if (this.isPpeLender)
        this.ppelenderName =
          this.lenderInfo.lenderNameAndId == "Others"
            ? this.lenderInfo.lenderNameAndId
            : this.lenderInfo.lenderNameAndId.lenderName;
    }
  }

  public async editLenderInfo(lender) {

    if (lender.phone) {
      let phone = lender.phone.split("");
      this.phoneNumber.first = phone[1] + phone[2] + phone[3];
      this.phoneNumber.middle = phone[6] + phone[7] + phone[8];
      this.phoneNumber.last = phone[10] + phone[11] + phone[12] + phone[13];
    }

    this.editLenderData = Object.assign({}, lender);
    this.defaultLabelName = "Edit Lender";
    this.defaultButtonName = "Update";
    this.lenderInfo = this.editLenderData;
    this.getAppovedLenderList();
    this.$modal.show("addLender");
  }

  public async opneConfirmPopup(id) {
    try {
      this.$modal.show("confirmDeleteFiles");
      this.lenderId = id;
    } catch (error) {
      console.log(error);
    }
  }

  public async del(yes) {
    try {
      let body = {
        lenderId: this.lenderId,
        brokerId: this.$brokerId
      };
      if (this.delete == yes) {
        let response = await Axios.post(
          BASE_API_URL + "onBoarding/deleteLender",
          body);
        this.getAppovedLenderList();
        this.$snotify.success("Lender deleted successfully");
        this.$modal.hide("confirmDeleteFiles");
      } else {
        this.$modal.hide("confirmDeleteFiles");
      }
    } catch (error) {}
  }

  public openModelAndSendMail(lender) {
    // this.$modal.show("openLenderModel");
    this.openSendModel = true;
    this.lenderId = lender;
  }

  public async refreshData(data) {
    this.openSendModel = false;
    // await this.getAppovedLenderList();
  }

  public hideModel() {
    this.$modal.hide("addLender");
    this.defaultLabelName = "Add Lender";
    this.defaultButtonName = "Add";
    this.lenderInfo = {
      lenderNameAndId: {}
    };
    this.selectLender = false;
    this.phoneNumber = {
      first: null,
      middle: null,
      last: null
    };
    this.phoneError = false;
  }

  public allowEditVendors(lender) {
    if (this.userType == "Broker") {
      if (
        lender.addedBy == this.$userId ||
        lender.addedByUserType == "Super Admin"
      ) {
        return true;
      } else {
        return false;
      }
    } else if (this.userType == "Admin Staff") {
      if (lender.addedBy == this.$userId || lender.brokerVendorReferenceId) {
        return true;
      }
    } else {
      return true;
    }
  }
  public async getData(lender) {
    this.lenderInfo.lenderNameAndId = {
      _id: lender._id,
      lenderName: lender.lenderName
    };

    if (this.lenderInfo.lenderNameAndId._id) {
      this.selectLender = false;
    }
  }

  mounted() {
    this.getAppovedLenderList();
  }

  getBrandingInformation() {
    return this.$store.state.brandingInformation || {};
  }
}
