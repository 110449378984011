












































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { BASE_API_URL } from "@/config";

import Axios from "axios";

@Component({})
export default class SendMailLender extends Vue {
  @Prop()
  public propsData: any;

  public async resendMailToLender() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "onBoarding/resendMailToLender",
        {
          lender: this.propsData
        });
      this.$snotify.success("Reminder send successfully");
      this.$modal.hide("openLenderModel");
      this.$modal.show("reminderSend");
    } catch (error) {
      console.log(error);
    }
  }

  public redirectToAddLender(){
    try{
       this.$router.push({
        path: this.$getCurrentUserTypePath("lender-relations"),
        query: {
          selectedLender: this.propsData.lenderName,
        }
      });
    } catch (error) {
      console.log('Redirecting to My account > Add lender' ,error);
    }
  }

  public hideModel(){
      this.$modal.hide("reminderSend");
      this.$modal.hide("disableNotifierModal");
      this.$emit("callMountFunction", this.propsData);
  }
  mounted() {
    if(!this.propsData.lenderId){
      this.$modal.show("disableNotifierModal");
    }else{
      this.$modal.show("openLenderModel");
    }
  }
}
