import { Component, Vue } from "vue-property-decorator";
import Axios from 'axios';

import { BASE_API_URL} from '@/config';

@Component
export default class LenderMixin extends Vue {

    public lenderInfo: any = {
        lenderNameAndId: {}
      };
      public inProcess = false;
      public lenderList: any = [];
      public isPpeLender = false;
      public ppelenderName = null;
      public hasLenderName = true;
      public addedLenders: any = [];
      public editLenderData: any = {};
      public defaultLabelName = "Add Lender";
      public defaultButtonName = "save lender";
      public lenderId = null;
      public delete = "yes";
      public inProcessing = false;
      public previousInProcess = false;
      public errorMessage = null;
      public hasErrorMessage = false;
      public searchByType=null;
      public searchString='';
      public selectLender: boolean = false;
      public phoneNumber:any={}


    
      public get sessionObject() {
        return this.$store.state.sessionObject;
      }
    
      //To get the Approved lender data
      public async getAppovedLenderList() {
        try {
          let body = {
            userId: this.$userId,
            brokerId: this.$brokerId,
            userType: this.$userType,
            type: this.defaultLabelName
          };
          let response = await Axios.post(
            BASE_API_URL + "onBoarding/getAppovedLenderList",
            body);
          this.lenderList = response.data.lender;
          this.addedLenders = response.data.addedData;
        } catch (error) {
          console.log(error.response);
        }
      }
    
      public async saveLenderInfo() {
        try {
          this.inProcess = true;
          let formValid = await this.$validator.validateAll();
          if (!formValid) {
            this.inProcess = false;
            return;
          }
          let lenderName = null;
          let lenderId = null;
          if (this.lenderInfo.lenderNameAndId == "Others"||this.lenderInfo.lenderNameAndId.lenderName == "Others") {
            lenderName = this.lenderInfo.lenderName;
            lenderId = this.lenderInfo.lenderNameAndId._id?this.lenderInfo.lenderNameAndId._id:null;;
          } else {
            lenderName = this.lenderInfo.lenderNameAndId.lenderName;
            lenderId = this.lenderInfo.lenderNameAndId._id;
          }
          this.lenderInfo.phone = `(${this.phoneNumber.first}) ${this.phoneNumber.middle}-${this.phoneNumber.last}`
          let lenderData: any = {
            lenderName: lenderName,
            lenderId: lenderId,
            brokerId: this.$brokerId,
            userType: this.$userType,
            lenderInfo: this.lenderInfo,
            type: this.defaultButtonName,
            userId: this.$userId
          };
          let response = await Axios.post(
            BASE_API_URL + "onBoarding/saveLenderInfo",
            { lenderData });
          // if (response.data.statusCode == 500) {
          //   this.inProcess = false;
          //   this.hasErrorMessage = true;
          //   this.errorMessage = response.data.message;
          // } else {
            this.getAppovedLenderList();
            this.$snotify.success("Lender added successfully!");
            this.inProcess = false;
            this.hideModel();
            
          // }
        } catch (error) {
          console.log(error);
        }
      }
    
      public changeLenderName(type) {
        this.hasErrorMessage = false;
        if (type == "input") {
          if (this.isPpeLender) this.ppelenderName = this.lenderInfo.lenderName;
        } else {
          this.hasLenderName = true;
          if (this.isPpeLender)
            this.ppelenderName =
              this.lenderInfo.lenderNameAndId == "Others"
                ? this.lenderInfo.lenderNameAndId
                : this.lenderInfo.lenderNameAndId.lenderName;
        }
      }
    
      public async editLenderInfo(lender) {
        let phone = lender.phone.split('')
        this.phoneNumber.first = phone[1]+phone[2]+phone[3]
        this.phoneNumber.middle = phone[6]+phone[7]+phone[8]
        this.phoneNumber.last = phone[10]+phone[11]+phone[12]+phone[13]
        this.editLenderData = Object.assign({}, lender);
        this.defaultLabelName = "Edit Lender";
        this.defaultButtonName = "Update";
        this.lenderInfo = this.editLenderData;
            this.getAppovedLenderList();
        this.$modal.show("openLenderModel");
      }
    
      public async opneConfirmPopup(id) {
        try {
          this.$modal.show("confirmDeleteFiles");
          this.lenderId = id;
        } catch (error) {
          console.log(error);
        }
      }
    
      public async del(yes) {
        try {
          let body = {
            lenderId: this.lenderId,
            brokerId: this.$brokerId
          };
          if (this.delete == yes) {
            let response = await Axios.post(
              BASE_API_URL + "onBoarding/deleteLender",
              body);
            this.getAppovedLenderList();
            this.$snotify.success("Lender deleted successfully");
            this.$modal.hide("confirmDeleteFiles");
          } else {
            this.$modal.hide("confirmDeleteFiles");
          }
        } catch (error) {}
      }
    
      public async continueAndNext() {
        try {
          this.inProcessing = true;
          await this.$store.dispatch("updatePreRequisiteOnServer", {
            userType: this.$userType,
            userId: this.$userId,
            brokerId: this.$brokerId,
            num: 1
          });
          await this.$store.dispatch(
            "updatePreRequisite",
            this.completedPreRequisite + 1
          );
          this.inProcessing = false;
        } catch (error) {
          console.log(error);
        }
      }
      get completedPreRequisite() {
        return this.$store.state.sessionObject.userInfo.completedPreRequisites;
      }
       //Updating pre-requisites on previous button
    public async modifyPreRequisite(num: any) {
      let userId = this.$store.state.sessionObject.userId;
      let userType = this.$store.state.sessionObject.userType;
      if (!userType)
          userType = this.$store.state.sessionObject.type;
      await this.$store.dispatch("updatePreRequisiteOnServer", { userId: userId, userType: userType, num: num, changeInState: true });
  }
    public async goPrevious() {
      this.previousInProcess = true;
      await this.modifyPreRequisite(this.completedPreRequisite - 1);
      this.previousInProcess = false;
    }
      public hideModel() {
        this.$modal.hide("openLenderModel");
        this.defaultLabelName = "Add Lender";
        this.defaultButtonName = "save lender";
        this.lenderInfo = {
          lenderNameAndId: {}
        };
        this.selectLender = false;
        this.phoneNumber = {}

      }
      public async getData(lender) {
    this.lenderInfo.lenderNameAndId = {
      _id: lender._id,
      lenderName: lender.lenderName
    };

    if (this.lenderInfo.lenderNameAndId._id) {
      this.selectLender = false;
    }
  }
  public moveToSecond() {
    if (this.phoneNumber.first.length == 3) {
      document.getElementById("second").focus();
    }
  }

  public moveToThird() {
    if (this.phoneNumber.middle.length == 3) {
      document.getElementById("third").focus();
    }
  }

}